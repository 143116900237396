import { NgxLoggerLevel } from 'ngx-logger';
import { LogLevel } from '@azure/msal-browser';

export const environment = {
  appVersion: require('../../package.json').version + '-sandbox',
  production: true,
  environment: 'sandbox',
  loggingLevel: NgxLoggerLevel.WARN,
  msalLoggingLevel: LogLevel.Warning,
  authority: 'https://login.microsoftonline.com/ddcdd9fc-eafc-4955-bdd4-1463255c7dc8/',
  azureClientId: '2b36d0f8-be06-46cf-b775-1d22b64cd72e',
  protectedResourceMap: [
    [ 'https://graph.microsoft.com/v1.0', [ 'User.Read', 'User.Read.All', 'User.ReadBasic.All', 'User.ReadWrite' ] ],
    [ '/api/*', [ 'api://2b36d0f8-be06-46cf-b775-1d22b64cd72e/Data.Read.All', 'api://2b36d0f8-be06-46cf-b775-1d22b64cd72e/Data.Write.All' ] ],
    // ... other scopes
  ],
  apiBaseUrl: window.location.origin,
  appInsights: {
    instrumentationKey: 'eec27001-b392-4166-8cb5-947c3c7bf762',
  },
  azureStorageConfig: {
    accountName: 'sgapps',
    // accountKey: '52TJznzdxb05E3CjIH7yTdB3wtUoVWdkT59qFjuE2YrApirPgvajc4KJi6OrhKJzCI0fhIn6yrHIzAZ3rXa/LQ==',
    imageContainer: 'sitewerx-ci-images',
    thumbnailContainer: 'sitewerx-ci-thumbnails',
  },
  stemsBaseUrl: window.location.origin + '/_stems',
  companyLink: '',
};
